import React from 'react';
import './ProjectBlock.css';

const ProjectBlock = ({ title, description, technologies, imageSrc, isAboutMe }) => {
    return (
        <div className={`project-block ${isAboutMe ? 'about-me-block' : ''}`} style={{ backgroundImage: `url(${imageSrc})` }}>
            <div className="project-content">
                <h4>{title}</h4>
                <p>{description}</p>
                <div className="technologies">
                    {technologies.map((tech, index) => (
                        <span key={index} className="tech-tag">{tech}</span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectBlock;
