import React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell
} from 'recharts';

const data = [
    { year: 2018, "ALL Time Citations": 3 },
    { year: 2019, "ALL Time Citations": 16 },
    { year: 2020, "ALL Time Citations": 24 },
    { year: 2021, "ALL Time Citations": 34 },
    { year: 2022, "ALL Time Citations": 42 },
    { year: 2023, "ALL Time Citations": 45 },
    { year: 2024, "ALL Time Citations": 60 },
];

// Custom Tooltip
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'rgba(0,0,139,0.75)', padding: '10px', border: '1px solid #ccc' }}>
                <p>{`Year: ${label}`}</p>
                <p>{`All Time Citations: ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

function CitationsChart() {
    // Dynamic color function based on value
    const barColor = (value) => {
        if(value < 20) return '#f56642'; // Red for low values
        else if(value < 40) return '#ffd700'; // Yellow for medium
        return '#4caf50'; // Green for high values
    };

    return (
        <div style={{ width: '100%', height: '500px', margin: '0 auto' }}>
            <ResponsiveContainer>
                <BarChart
                    data={data}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar dataKey="ALL Time Citations">
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={barColor(entry["ALL Time Citations"])} />
                            ))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default CitationsChart;
