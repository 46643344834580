import App from "../App";
import Navbar from "../NavBoot";
import './Theme.css';
import Title from "../components/Title";
import checklist from "../icons/checklist.gif";
import Employment from "../components/Employment";
import React from "react";

function Fintech() {
    return (
        <div className="fintech">
            <Title iconsrc={checklist} SectionTitle={"Experience"}/>

            <Employment company={"Huawei"} date={"Jul 2023 - Present"} title={"Associate Researcher"}
                        description={"Algorithm Development: Designing and refining machine learning models to accurately predict energy patterns and efficiency in microgrid systems." +
                            " This involves handling vast datasets and ensuring algorithms are both robust and adaptable to changing environmental factors.\n\nData Analysis & Interpretation:" +
                            " Conducting thorough statistical analyses to interpret complex time series data. This role involves transforming raw data into insightful" +
                            " trends and actionable information, aiding in strategic decision-making processes.\n\nInnovation in Renewable Energy:" +
                            " Contributing to Huawei's commitment to renewable energy through the implementation of cutting-edge technologies." +
                            " My work supports the optimization of photovoltaic systems, enhancing their efficiency and reliability.\n\nCollaboration & Knowledge Sharing:" +
                            " Working closely with cross-functional teams to integrate machine learning insights into broader energy projects."}/>

            <Employment company={"Lagrange Labs"} date={"Aug 2022 - Jun 2023"} title={"Block-chain Developer"}
                        description={"Devops engineer responsible for :\n" +
                            "1.Node management of several chains such as Ethereum , Polygon ,...\n" +
                            "2.Implementation of Trusted Execution Environment ( TEE ) Based on AMD EPYC processors"}/>
            <Employment company={"York University"} date={"Sep 2019 - Present"} title={"Teaching Assistant"}
                        description={"Adapt teaching methods and instructional materials to meet students' varying needs, abilities, and interests.\n" +
                            "Assign and grade classwork.\n" +
                            "Keep regularly scheduled office hours to advise and assist students.\n" +
                            "Prepare and administer written, oral, and performance tests, and issue grades in accordance with performance.\n" +
                            "Observe and evaluate students' work to determine progress and make\n" +
                            "suggestions for improvement.\n" +
                            "I have been the teaching assistant for the following courses over the last two years:" +
                            "1. Teaching Assistant-Computing for Psychology ( EECS 1570-Winter semester 2022 )\n" +
                            "2. Teaching Assistant-Introduction to Embedded Systems ( EECS 2032-Winter semester 2022 )\n" +
                            "3. Teaching Assistant-Introduction to Embedded Systems ( EECS 2032-Fall semester 2021 )\n" +
                            "4. Teaching Assistant-Computational Thinking ( EECS 1011-Fall semester 2021)\n" +
                            "5. Teaching Assistant-Introduction to Embedded Systems ( EECS 2032-Winter semester 2021 )\n" +
                            "6. Teaching Assistant-Computer Use: Fundamentals ( EECS 1520-Winter semester 2021 )\n" +
                            "7. Teaching Assistant-Introduction to Embedded Systems ( EECS 2032-Fall semester 2020 )\n" +
                            "8- Teaching Assistant-Introduction to Embedded Systems ( EECS\n" +
                            "2032-Fall semester 2022 )" + "9- Teaching Assistant -  LE/EECS 3101 Design and Analysis of Algorithms (Winter semester 2023) "}/>
            <Employment company={"York University"} date={"Sep 2019 - Present"} title={"Research Assistant"}
                        description={"Performing research and making cutting-edge sets of computational and numerical tools for analyzing the energy industry, which is growing quickly.\n" +
                            "My PhD thesis title is \"Classical and Quantum Computing Methods for Simulation of Geomagnetic Disturbance Effects on Modern Power Systems\""}/>
            <Employment company={"MeshGrid HR"} date={"Feb 2022 - Apr 2022"} title={"NLP Engineer"}
                        description={"Creating NLP solutions for web automation and resume scanning based on well known NLP libraries such as SPACY and nltk"}/>
            <Employment company={"ChargeLab"} date={"Feb 2021 - Dec 2021"} title={"Research and Development Engineer"}
                        description={"I was in charge of :\n" +
                            "Creating new machine learning algorithms for EV charging systems.\n" +
                            "weekly meetings with the chief technology officer and the preparation of industrial proposals.\n" +
                            "Creating a timetable for the proposed projects using project management skills such as a Gantt chart."}/>
            <Employment company={"IBZ-Mitacs Accelerate"} date={"May 2020 - Dec 2020"} title={"Entrepreneurship Intern"}
                        description={"Lab2Market (L2M) is the first national-level program in Canada to fund and equip graduate students and their faculty supervisors with the entrepreneurial skills needed to assess the commercial viability of their university-based research innovations. I was part of this program with collaboration with VentureWell and Mitacs .\n" +
                            "During my internship I was being prepared for:\n" +
                            "Creating Business Models and Customer Development\n" +
                            "Creating Value Proposition\n" +
                            "Creating Customer Relationships\n" +
                            "Creating Revenue Models\n" +
                            "Getting Familiar with the Innovation Within Platform ( https://innovationwithin.com/ )\n" +
                            "Creating Complete Business Model Canvas ( BMC ) for creating a new startup"}/>
        </div>
    )
}

export default Fintech;