import { useSpring, animated } from '@react-spring/web';
import React from 'react';
import './Employment.css'; // Assuming you will create this CSS file for styles

function Employment({ title, company, date, description, link }) {
    // Animation for the component to fade in and slide up slightly
    const styles = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { duration: 500 }, // Adjust the duration as needed
        delay: 100, // Delay before the animation starts
    });

    return (
        <animated.div className="employment-container" style={styles}>
            <div className="employment-header">
                <h1 className="employment-title">{title}</h1>
                <h2 className="employment-company">{company}</h2>
                <h3 className="employment-date">{date}</h3>
            </div>
            <p className="employment-description">{description}</p>
            {link && (
                <a href={link} className="employment-link" target="_blank" rel="noopener noreferrer">
                    {link}
                </a>
            )}
        </animated.div>
    );

}

export default Employment;
