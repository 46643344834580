import React, { useState } from 'react';
import useMeasure from 'react-use-measure';
import { useSpring, animated } from '@react-spring/web';

const ProgressBar = ({ percent }) => {
    const [open, setOpen] = useState(false);
    const [ref, bounds] = useMeasure();
    const percentWidth = open ? bounds.width : (bounds.width * percent / 100);

    const barProps = useSpring({
        width: percentWidth,
        backgroundColor: percent > 50 ? 'rgba(0, 128, 0, 0.7)' : 'rgba(255, 165, 0, 0.7)', // Greenish for >50%, Orange otherwise
        config: { tension: 200, friction: 30 },
    });

    const labelProps = useSpring({
        number: percent,
        from: { number: 0 },
    });

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "20px" }}>
            <div
                style={{
                    width: "80%", // Responsive with max-width
                    maxWidth: "600px", // Max width to avoid being too wide on large screens
                    height: "40px",
                    border: "2px solid #ddd",
                    borderRadius: "20px",
                    overflow: "hidden",
                    cursor: "pointer",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Soft shadow for depth
                }}
                ref={ref}
                onClick={() => setOpen(!open)}
            >
                <animated.div style={{
                    ...barProps,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: barProps.width.to(w => `${w}px`),
                    height: "100%",
                    borderRadius: "18px", // Slightly less than container to fit nicely
                }}>
                    <animated.span style={{
                        color: "white",
                        fontWeight: "bold",
                    }}>
                        {labelProps.number.to(n => `${n.toFixed(0)}%`)}
                    </animated.span>
                </animated.div>
            </div>
        </div>
    );
};

export default ProgressBar;
