import { useRouteError } from "react-router-dom";
import {Alert, Navbar} from "react-bootstrap";
import './Theme.css';
import {Spinner} from "react-bootstrap";


export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="error-page">
            <h1 className="error">Oops!</h1>
            <p className="error">Sorry, an unexpected error has occurred.</p>
            <p className="error">
            <Alert.Link className="error" href="/">Go to home page</Alert.Link>

            </p>

            <p className="error">
                <i>{error.statusText || error.message}</i>
            </p>

            <div className="space"></div>

            <p className="error">Parham Mohammadi</p>

        </div>
    );
}