import App  from "../App";
import Navbar from "../NavBoot";
import './Theme.css';
import Table from 'react-bootstrap/Table';


function Contact() {
    return (
        <div className="contact">
            <Navbar />
            <div className="Contact">
            <h2>Contact</h2>
            </div>
        <div className="Tablediv">
    <Table striped bordered hover variant="dark" size="sm">
        <thead>
        <tr>
            <th>First Name</th>
            <th>Last Name</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Mark</td>
            <td>Otto</td>
        </tr>
        <tr>
            <td>Thornton</td>
            <td>@fat</td>
        </tr>
        <tr>
            <td colSpan={2}>Larry the Bird</td>
        </tr>
        </tbody>
    </Table>
        </div>
        </div>

    )
}

export default Contact;