import React from 'react';
import { useInView } from '@react-spring/web';
import Percent from "./test";
import './Skills1.css'; // Assuming you will create this CSS file for styles

function Skills1({ skills, percentage }) {
    const [ref, inView] = useInView({
        threshold: 0.5, // Adjust this value as needed to trigger animation when the element is 50% in view
        triggerOnce: true, // Ensures the animation only plays once
    });

    // Style adjustments and animations are moved to CSS for simplicity and maintainability
    return (
        <div ref={ref} className={`skill-container ${inView ? 'visible' : ''}`}>
            <div className="skill-name">{skills}</div>
            <Percent percent={percentage} />
        </div>
    );
}

export default Skills1;
