import React from 'react';
import Publication from './Publications';

const Publications_List = () => {
    const publications = [
        {
            title: "GMDH Neural Networks-Based modeling of variable power inductor",
            authors: "P Koohi, P Mohammadi, R Samanbakhah, FM Ibanez",
            publication: "Preprints",
            year: 2021
        },
        {
            title: "A New Fully Soft-Switched, Single-Stage LLC Resonant Based Grid Connected Inverter",
            authors: "P Mohammadi, J Lam",
            publication: "2021 IEEE Applied Power Electronics Conference and Exposition (APEC)",
            pages: "1438-1443",
            year: 2021
        },
        {
            title: "A New Fully Soft-Switched, Single-Stage Bidirectional LLC Resonant Based AC/DC Converter for Hybrid Micro-grid with Active Ripple Energy Storage",
            authors: "P Mohammadi, J Lam",
            publication: "2021 IEEE Energy Conversion Congress and Exposition (ECCE)",
            pages: "632-638",
            year: 2021
        },
        {
            title: "A dual-input high-gain bidirectional DC/DC converter for hybrid energy storage systems in DC grid applications",
            authors: "FD Hernandez, R Samanbakhsh, P Mohammadi, FM Ibanez",
            publication: "IEEE Access 9",
            pages: "164006-164016",
            year: 2021
        },
        {
            title: "A novel non-coupled non-isolated double-input bidirectional high-gain converter for hybrid energy storage system",
            authors: "P Mohammadi, R Samanbakhsh, FD Hernandez, P Koohi, F Ibanez",
            publication: "2019 IEEE 10th International Symposium on Power Electronics for Distributed",
            year: 2019
        },
        {
            title: "High frequency transformer design for specific static magnetising and leakage inductances using combination of multi-layer perceptron neural networks and fem simulations",
            authors: "P Mohammadi, R Samanbakhsh, P Koohi, F Ibanez",
            publication: "2019 IEEE 10th International Symposium on Power Electronics for Distributed",
            year: 2019
        },
        {
            title: "Double-input high-gain bidirectional DC-DC converter for hybrid energy storage systems in DC-micro grid",
            authors: "P Mohammadi, JS Moghani",
            publication: "Power Electronics, Drives Systems and Technologies Conference (PEDSTC)",
            year: 2018
        },
        {
            title: "A Novel Double-Loop Control Structure Based on Fuzzy-PI and Fuzzy-PR Strategies for Single-Phase Inverter in Photovoltaic Application",
            authors: "P Mohammadi, B Azimian, A Shahirinia",
            publication: "2018 North American Power Symposium (NAPS)",
            pages: "1-6",
            year: 2018
        },
        {
            title: "LVRT capability enhancement of single-phase grid connected PV array with coupled supercapacitor",
            authors: "P Mohammadi, A Eskandari, J Milimonfared, JS Moghani",
            publication: "Power Electronics, Drives Systems and Technologies Conference (PEDSTC)",
            year: 2018
        },
        {
            title: "Analysis of Six-phase Induction Machine Compensation Methods under Fault Conditions Due to Open Phases",
            authors: "P Mohammadi, F Ibanez, P Koohi, R Samanbakhsh",
            publication: "IEEE 9th International Symposium on Power Electronics for Distributed Generation",
            year: 2018
        },
        {
            title: "Inrush Current Limiter Design for a High Power Transformer Using Multi-layer Perceptron Neural Networks",
            authors: "P Mohammadi, F Ibanez, P Koohi, R Samanbakhsh",
            publication: "IEEE 9th International Symposium on Power Electronics for Distributed Generation",
            year: 2018
        },
        {
            title: "Effect of the number of phases on the performance of multiphase induction machine under healthy and open phase fault conditions",
            authors: "R Samanbakhsh, F Ibanez, P Koohi, P Mohammadi",
            publication: "IEEE 9th International Symposium on Power Electronics for Distributed Generation",
            year: 2018
        },
    ];

    return (
        <div>
            {publications.map((publication, index) => (
                <Publication
                    key={index}
                    title={publication.title}
                    authors={publication.authors}
                    publication={publication.publication}
                    year={publication.year}
                    pages={publication.pages}
                />
            ))}
        </div>
    );
};

export default Publications_List;
