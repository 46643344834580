import {useInView, animated} from '@react-spring/web'

function Title({SectionTitle, iconsrc}) {
    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 0,
                x: 0,
            },
            to: {
                opacity: 1,
                y: 0,
                x: 20,
            },
        }),
        {threshold: 0.1}
    )

    return (
        <div ref={ref} style={{position: "left", padding: "0"}}>
            <animated.div ref={ref} style={{
                ...springs,
                margin: "2vw",
                left: "15vw",
                width: "50vw",
                justify: "left",
                color: "black",
                fontWeight: "bold",
                fontFamily: "Franklin",
                fontSize: "calc(20px + 10 * ((100vw - 320px) / 680))",
                textAlign: "center",
                position: "relative"
            }}>
                <img src={iconsrc} style={{width: "3vw", height: "auto" , border : "1px black " , borderRadius:"15px" }}/>
                {SectionTitle}
            </animated.div>
        </div>

    )
}

export default Title