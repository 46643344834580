import React from 'react';
import ReactDOM from 'react-dom/client';
import{
    createBrowserRouter,
    RouterProvider,
    Route
} from "react-router-dom";
import App from './App';// import App from "./App";
import reportWebVitals from './reportWebVitals';// import './index.css';
import Navboot from "./NavBoot";//`import Navboot from "./NavBoot";`
import 'bootstrap/dist/css/bootstrap.min.css'; // Import precompiled Bootstrap css
// This is for importting the routes from the routes folder
import Contact from "./routes/contact";
import Quantum  from "./routes/Quantum";
import Fintech from "./routes/Fintech";
import Education from "./routes/Education";
import Skills from "./routes/Skills";
import Summary from "./routes/Summary";
import  Crypto from "./routes/crypto";
import ErrorPage from "./routes/error-page";
import Gans from "./routes/Gans";
import Expreince from "./routes/Expreince";
//Creating the router
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            { path: "experience", element: <App /> },
            { path: "education", element: <App /> },
            { path: "skills", element: <App /> },
            { path: "certificates", element: <App /> },
            { path: "honors", element: <App /> },
            { path: "contact", element: <App /> },
            { path: "publications", element: <App /> },
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
