import React, { useState } from 'react';
import './Chatbot.css';

const faqData = {
  'education': 'I am a PhD candidate at York University, focusing on AI and Quantum Computing.',
  'skills': 'My key skills include Python, TensorFlow, Qiskit, and power systems optimization.',
  'experience': 'I have experience as a Research Intern at Huawei, working on AI-driven power system optimization.',
  'projects': 'I have worked on projects involving AI-driven power system optimization, quantum computing for energy systems, and blockchain-based energy trading platforms.',
  'contact': 'You can reach me at your.email@example.com or connect with me on LinkedIn.',
};

function Chatbot() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const lowercaseInput = input.toLowerCase();
    const answer = Object.keys(faqData).find(key => lowercaseInput.includes(key));
    const response = faqData[answer] || "I'm sorry, I don't have information about that. Please try asking about my education, skills, experience, projects, or contact information.";
    
    setMessages([...messages, { type: 'user', text: input }, { type: 'bot', text: response }]);
    setInput('');
  };

  return (
    <div className={`chatbot ${isOpen ? 'open' : ''}`}>
      <button className="chatbot-toggle" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Close' : 'Chat with me'}
      </button>
      {isOpen && (
        <div className="chatbot-container">
          <div className="chatbot-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.type}`}>
                {message.text}
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit}>
            <input 
              value={input} 
              onChange={(e) => setInput(e.target.value)} 
              placeholder="Ask me about my resume" 
            />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default Chatbot;